import axios from 'axios';
import Main from "../callBlock/Main";
import ChatHistoryBlock from "../chatHistory/chatHistoryBlock";
import ChatSettingsBlock from "../chatSettings/chatSettingsBlock";
import { useEffect, useState } from "react";
import Feedback from "../appHeader/Feedback";
import AppHeader from "../appHeader/AppHeader";
import Tutorial from "../appHeader/Tutorial";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function Home({
  component: Component,
  auth: { isAuthenticated, loading, user },
  ...rest
}) {
  const [dialogue, setDialogue] = useState([]);

  const [goal, setGoal] = useState(null);
  const [reason, setReason] = useState(null);
  const [productDetail, setProductDetail] = useState(null);
  const [companyDescription, setCompanyDescription] = useState(null);

  const [profileAge, setProfileAge] = useState("18-25");
  const [profileGender, setProfileGender] = useState("Male");
  const [profileIncomeBracket, setProfileIncomeBracket] = useState("Low");
  const [profileOccupation, setProfileOccupation] = useState("CEO");
  const [profileIndustry, setProfileIndustry] = useState("Real estate");
  const [profileCompany, setProfileCompany] = useState(null);
  const [profileLocation, setProfileLocation] = useState("California, USA");
  const [profileInterests, setProfileInterests] = useState(null);
  const [profileGoals, setProfileGoals] = useState(null);
  const [profileAdditionalInformation, setProfileAdditionalInformation] =
    useState(null);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [checkLoading, setCheckLoading] = useState(true);
  const [trial, setTrial] = useState(false);

  function updateDialogue(type, content) {
    setDialogue((dialogue) => [...dialogue, { type: type, content: content }]);
  }

  const getSubscription = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}/api/subscription`
    );
    if(response.data.subscription) setHasSubscription(true);
    else{
      if(response.data.trial.length == 0) setTrial(true)
      setHasSubscription(false);
    }
    setCheckLoading(false);
  };

  useEffect(() => {
    getSubscription()
  }, [])

  return !isAuthenticated && !loading ? (
    <Navigate to="/login"></Navigate>
  ) : (
    <div className="container-fluid">
      {checkLoading ?
        <div className="report-loader" style={{ zIndex: "1" }}>
          <div className="report-inner report-one"></div>
          <div className="report-inner report-two"></div>
          <div className="report-inner report-three"></div>
        </div> : <>
        {console.log(trial)}
          {trial && <div className='popup'>
            Subscribe to unlock your 14 day free trial!
            <div onClick={()=>setTrial(false)}>X</div>
          </div>}
          <div className="row">
            <button
              className="btn btn-primary w-100 d-block tutorial-button"
              data-bs-toggle="modal"
              data-bs-target="#tutorialModal"
            >
              Tutorial
            </button>
            <Tutorial />
            <AppHeader hasSubscription = {hasSubscription} user = {user}/>
            <ChatHistoryBlock dialogue={dialogue} />
            <Main
              onAddNewMessage={updateDialogue}
              dialogue={dialogue}
              clearDialogue={() => {
                setDialogue([]);
              }}
              goal={goal}
              reason={reason}
              productDetail={productDetail}
              companyDescription={companyDescription}
              profileAge={profileAge}
              profileGender={profileGender}
              profileIncomeBracket={profileIncomeBracket}
              profileOccupation={profileOccupation}
              profileIndustry={profileIndustry}
              profileCompany={profileCompany}
              profileLocation={profileLocation}
              profileInterests={profileInterests}
              profileGoals={profileGoals}
              profileAdditionalInformation={profileAdditionalInformation}
            />

            <ChatSettingsBlock
              onSetGoal={(goal) => setGoal(goal)}
              onSetReason={(reason) => setReason(reason)}
              onSetProductDetail={(product) => setProductDetail(product)}
              onSetCompanyDescription={(description) =>
                setCompanyDescription(description)
              }
              onSetAge={(age) => setProfileAge(age)}
              onSetGender={(gender) => setProfileGender(gender)}
              onSetIncomeBracket={(bracket) => setProfileIncomeBracket(bracket)}
              onSetOccupation={(occupation) => setProfileOccupation(occupation)}
              onSetIndustry={(industry) => setProfileIndustry(industry)}
              onSetCompany={(company) => setProfileCompany(company)}
              onSetLocation={(location) => setProfileLocation(location)}
              onSetInterests={(interests) => setProfileInterests(interests)}
              onSetGoals={(goals) => setProfileGoals(goals)}
              onSetAdditionalInfo={(additionalInformation) =>
                setProfileAdditionalInformation(additionalInformation)
              }
            />
          </div>
          <button
            className="btn btn-primary w-100 d-block feedback-button"
            data-bs-toggle="modal"
            data-bs-target="#feedBackModal"
          >
            Feedback
          </button>
          <Feedback />
        </>
      }
    </div>
  );
}

Home.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
