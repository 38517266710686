import axios from "axios";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppHeader from "../appHeader/AppHeader";
import Modal from "../common/Modal";
import { setAlert } from "../../actions/alert";
import { Navigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

function Profile({ setAlert, auth: { isAuthenticated, loading, user } }) {
  const [subscription, setSubscription] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [formData, setFormdata] = useState({
    userName: user?.name,
    prevPassword: "",
    password: "",
    password2: "",
  });
  const [checkLoading, setCheckLoading] = useState(true);

  // Pull name, email, passowrd from formData
  const { userName, prevPassword, password, password2 } = formData;
  const onChange = (e) =>
    setFormdata({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password !== password2) {
      console.log("password", password, password2);
      // Error Alert
      setAlert("Passwords do not match", "danger");
    } else {
      try{
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/api/users/change-password`, {password: prevPassword, newpassword: password2});
        if(response.data.ok){
          setAlert("Password successfully changed.", 'success')
        }
      }catch(err){
        const errors = err.response.data.errors;
        errors.forEach((error) => setAlert(error.msg, 'danger'));
      }
    }
    setOpenModal(false);
  };

  const cancelSubscription = async () => {
    setCheckLoading(true);
    try{
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/api/subscription/cancel-subscription`);
      if(response.data.ok){
        setAlert("Subscription successfully cancelled.", 'success')
      }
    }catch(err){
      const errors = err.response.data.errors;
      errors.forEach((error) => setAlert(error.msg, 'danger'));
    }
    setCheckLoading(false);
  }

  const createCheckoutSession = async () => {
    setCheckLoading(true);
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/api/subscription/create-checkout-session`);
    const result = stripe.redirectToCheckout({
      sessionId: response.data.id
    })
    if (result.error) {
      console.log(result.error)
    }
    setCheckLoading(false);
  }

  const getSubscription = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}/api/subscription`
    );
    setSubscription(response.data.subscription);
    setCheckLoading(false);
  };
  useEffect(() => {
    getSubscription();
  }, []);
  return !isAuthenticated && !loading ? (
    <Navigate to="/login"></Navigate>
  ) : checkLoading ?
    (<div className="report-loader" style={{ zIndex: "1" }}>
      <div className="report-inner report-one"></div>
      <div className="report-inner report-two"></div>
      <div className="report-inner report-three"></div>
    </div>) :
    (<div>
      <AppHeader hasSubscription={subscription ? true : false} user={user} />
      <div className="page-content page-container" id="page-content">
        <div className="padding d-flex justify-content-center">
          <div className="row container d-flex justify-content-center m-l-0">
            <div className="col-xl-8 col-md-12">
              <div className="card user-card-full">
                <div className="row m-l-0 m-r-0">
                  <div className="col-sm-4 bg-c-lite-green user-profile">
                    <div className="card-block text-center text-white">
                      <div className="m-b-25">
                        <img
                          src="https://img.icons8.com/bubbles/100/000000/user.png"
                          className="img-radius"
                          alt="User-Profile-Image"
                        />
                      </div>
                      <h6 className="f-w-600">{user?.name}</h6>
                      <i className=" mdi mdi-square-edit-outline feather fas fa-edit m-t-10 f-16" onClick={() => setEditProfile(!editProfile)}></i>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="card-block">
                      <h6 className="m-b-20 p-b-5 b-b-default f-w-600">
                        Information
                      </h6>
                      <div className="row">
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600">Name</p>
                          {editProfile ? <input
                            className="form-control"
                            type="text"
                            placeholder="Change your name.."
                            name="name"
                            value={userName}
                            onChange={(e) => onChange(e)}
                            minLength="6"
                            required
                          /> : <h6 className="text-muted f-w-400">{user?.name}</h6>}
                        </div>
                        <div className="col-sm-6">
                          <p className="m-b-10 f-w-600">Email</p>
                          <h6 className="text-muted f-w-400">{user?.email}</h6>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-2">
                        <div
                          className="btn btn-outcircle"
                          target={"_blank"}
                          rel="noreferrer"
                          onClick={() => setOpenModal(true)}
                        >
                          Change Password
                        </div>
                      </div>
                      <h6 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600">
                        Subscription Info
                      </h6>
                      <div className="row">
                        <div className="col-sm-12">
                          <p>
                            {subscription
                              ? `Your subscription will expire in ${subscription.end_date.slice(0, 10)}`
                              : `You did not subscribe.`}
                          </p>
                        </div>
                        <div className="col-sm-6"></div>
                        {subscription ? <div
                          className="btn btn-danger"
                          target={"_blank"}
                          rel="noreferrer"
                          onClick={cancelSubscription}
                        >
                          Unsubscribe
                        </div> : <div
                          className="btn btn-outcircle"
                          target={"_blank"}
                          rel="noreferrer"
                          onClick={createCheckoutSession}
                        >
                          Subscribe
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Modal isOpen={openModal} closeModal={() => setOpenModal(false)}>
                <h5>Change Password</h5>
                <form
                  className="form d-flex align-items-center mt-3"
                  onSubmit={(e) => onSubmit(e)}
                >
                  <div className="form-group">
                    <input
                      className="form-control auth-input"
                      type="password"
                      placeholder="Enter the preview password"
                      name="prevPassword"
                      value={prevPassword}
                      onChange={(e) => onChange(e)}
                      minLength="6"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control auth-input"
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      onChange={(e) => onChange(e)}
                      minLength="6"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control auth-input mb-4"
                      type="password"
                      placeholder="Confirm Password"
                      name="password2"
                      value={password2}
                      onChange={(e) => onChange(e)}
                      minLength="6"
                      required
                    />
                  </div>
                  <div>
                    <input
                      type="submit"
                      className="btn btn-primary position-absolute modal-btn"
                      value="Save Change"
                    />
                  </div>
                </form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>);
}

Profile.propTypes = {
  setAlert: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setAlert })(Profile);
