import { Link } from "react-router-dom";
import "./AppHeader.css";
import axios from 'axios';
import { logout } from '../../actions/auth';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";

function AppHeader(props) {
  const [checkLoading, setCheckLoading] = useState(false);

  const createCheckoutSession = async () => {
    setCheckLoading(true);
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_API}/api/subscription/create-checkout-session`);
    const result = stripe.redirectToCheckout({
      sessionId: response.data.id
    })
    if (result.error) {
      console.log(result.error)
    }
    setCheckLoading(false);
  }

  return <header className="app_header">
    {checkLoading &&
      <div className="report-loader" style={{ zIndex: "1" }}>
        <div className="report-inner report-one"></div>
        <div className="report-inner report-two"></div>
        <div className="report-inner report-three"></div>
      </div>}
    <div className="header_components d-flex justify-content-between">
      <Link to={"/home"}>
        <img
          src={`${process.env.PUBLIC_URL}/pitchlab.png`}
          className="logo"
          alt="Company_Logo"
        />
      </Link>
      <div className={"promotion text-center"}>
        Note: $5 to the first 100 users to give us feedback on our software ❤️
        <br />
        Lifetime access to the software for the first 25 people to get on a
        Zoom.
      </div>
      <div className="app-header-btn-container">
        {!props.hasSubscription && (
          <div className="btn btn-warning" target={"_blank"} rel="noreferrer" onClick={createCheckoutSession}>
            Subscribe
          </div>
        )}
        <a
          href="https://calendly.com/pitch-lab/brainstorming-call"
          className="btn btn-primary"
          target={"_blank"}
          rel="noreferrer"
        >
          Book a Call
        </a>
        <div className="dropdown">
          <div className="profile-btn" style={{ display: "flex" }}>
            <i className="fa fa-user-circle dropbtn mr-2"></i>
            <div>{props.user?.name}</div>
          </div>
          <div className="dropdown-content">
            <Link to={"/profile"}>Profile</Link>
            <a onClick={props.logout} href="#">Log out</a>
          </div>
        </div>
      </div>
    </div>
  </header>;
}

AppHeader.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(AppHeader);
