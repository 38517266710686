import "./App.css";
import { useEffect, Fragment, StrictMode } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Home from "./components/home/Home";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Alert from "./components/layout/Alert";
import Profile from "./components/profile/Profile";
import PrivateRoute from "./components/routing/PrivateRoute";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Outlet,
} from "react-router-dom";

//Redux
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";
import Success from "./components/payment/Success";
import Cancel from "./components/payment/Cancel";

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },{
        path: "/login",
        element: <Login/>,
    },{
        path: "/register",
        element: <Register/>,
    },{
        path: "/home",
        element: <Home />,
    },{
        path: "/success",
        element: <Success />,
    },{
        path: "/cancel",
        element: <Cancel />,
    },{
        path: "/profile",
        element: <Profile />,
    },
]);

function App() {
    useEffect(() => {
        store.dispatch(loadUser());
    }, []);

    return (
        <Provider store={store}>
            <Alert/>
            <StrictMode>
                <RouterProvider router={router} />
            </StrictMode>
        </Provider>
    );
}

export default App;
