import React, { Fragment, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import PropTypes from "prop-types";
// import { setAlert } from '../../actions/alert';

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormdata] = useState({
    email: "",
    password: "",
  });

  // Pull name, email, passowrd from formData
  const { email, password } = formData;

  const onChange = (e) =>
    setFormdata({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  // Navigate if Logged in
  if (isAuthenticated) {
    return <Navigate to="/home" />;
  }

  return (
    <>
      <div className="auth-content">
        <h1 className="large text-primary mb-4">Sign In</h1>
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <input
              className="form-control auth-input"
              type="email"
              placeholder="Enter the Email Address"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              required
            />
          </div>
          <div className="form-group">
            <input
              className="form-control auth-input"
              type="password"
              placeholder="Enter the Password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              minLength="6"
              required
            />
          </div>

          <input type="submit" className="btn btn-primary" value="Login" />
        </form>
        <p className="my-1">
          Don't have an account? <Link to="/register">Sign Up</Link>
        </p>
      </div>
    </>
  );
};
Login.propTypes = {
  // setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

// export default connect(mapStateToProps, { setAlert, login })(Login);
export default connect(mapStateToProps, { login })(Login);
