import axios from 'axios';
import {useState} from "react";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function CallButtons(props) {
    const [isCalling, setIsCalling] = useState(false)

    const hasSubscription = async () => {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_API}/api/subscription`
        );
        return !!response.data.subscription
    };

    async function makeCall() {
        const check = await hasSubscription();
        if(!check){
            console.log("You did not subscribe")
            return props.setAlert("You did not subscribe", "danger")
        }
        props.onCallStarting()
        setIsCalling(true)
    }


    return (
        <div className={'call-buttons-block d-flex justify-content-around'}>
            {!isCalling && <div className={'calls-list d-flex'}>
                <div className="icon icon-fill my-2" onClick={makeCall}><i className={'fs-1 fa fa-phone'}/></div>
            </div>}
            {isCalling &&
                <div className={'calls-list d-flex'}>
                    <div className="icon icon-fill me-2 my-2" onClick={() => {
                        setIsCalling(false)
                        props.onEndCalling()
                    }}><i className={'fs-1 fa fa-phone-slash'}/></div>
                    <div className={'calls-list'}>
                        <div className="icon icon-fill ms-2 my-2" onClick={() => {
                            props.onStopTalking()
                        }}><i className="fs-1 fa-solid fa-play"/></div>
                    </div>
                </div>}
        </div>
    )
}

CallButtons.propTypes = {
    setAlert: PropTypes.func.isRequired,
};

export default connect(null, { setAlert })(CallButtons);