import axios from "axios";
import React, { useEffect } from "react";
import { Navigate, Link } from "react-router-dom";

const Cancel = () => {
  return (
    <>
      <div className="success-page">
        <div class="card">
          <div
            style={{
              borderRadius: "200px",
              height: "200px",
              width: "200px",
              margin: "auto",
            }}
          >
            <i class="failmark">Oops...</i>
          </div>
          <p className="success-pa">
            If you pucharge a subscription,
            &nbsp;<Link to={"/home"}>click here!</Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Cancel;
