import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Success = () => {
  const [trial, setTrial] = useState(false);
  const [checkLoading, setCheckLoading] = useState(true);

  const subscriptionCheck = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_API}/api/subscription/payment-check`,
      config
    );

    setCheckLoading(false)

    if (!response.data.ok) {
      window.location.href = "./cancel";
    }
    if (response?.data?.subscription?.subscription_type == "trialing") {
      setTrial(true)
    }
  };

  useEffect(() => {
    subscriptionCheck();
  }, []);

  return (
    checkLoading ?
      <div className="report-loader" style={{ zIndex: "1" }}>
        <div className="report-inner report-one"></div>
        <div className="report-inner report-two"></div>
        <div className="report-inner report-three"></div>
      </div> : <>
        <div className="success-page">
          <div class="card">
            <div
              style={{
                borderRadius: "200px",
                height: "200px",
                width: "200px",
                background: "#F8FAF5",
                margin: "auto",
              }}
            >
              <i class="checkmark">✓</i>
            </div>
            <h1 className="success-title">{trial ? "14 day free trial unlocked" : "Success"}</h1>
            <p className="success-pa">
              {trial ? "You can cancel at any point during the trial" : "Congratulation!"}
            </p>
            <Link to={"/home"}>Try Pitch App</Link>
          </div>
        </div>
      </>
  );
};

export default Success;
